import { DonateFormItem } from './DonateCommon';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import HR from '@/components/HR';
import InputNumber from '@/components/Input/InputNumber';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import Select from '@/components/Select';
import SupportPhrase from '@/components/SupportPhrase';
import { H4 } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import {
  SUPPORT_TERMS,
  SUPPORT_TERM_ONCE,
  SUPPORT_TERM_REGULAR,
  useFindSupportCategory,
  useSupportCategoriesForPayment,
} from '@/helpers/SupportHelper';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const FormContainer = styled.div`
  ${InputRadioGroup} {
    ${breakpoint(`tablet`)} {
      ${InputText} {
        height: 44px;
      }
    }
  }
`;

const FormTip = styled.div<{
  type?: string;
}>`
  padding: 22px 20px;
  ${({ type }) => {
    if (type === `blue`) {
      return `
        background-color: #e2f0f6;
        color: #1cabe2;
        `;
    }
    if (type === `gray`) {
      return `
        background-color: #f8f9fa;
        color: #2d2926;
        `;
    }
    return ``;
  }}
`;

const FormTotal = styled.div`
  position: relative;
  padding: 32px 0;
  margin: 56px 0 80px 0;
  border-top: 1px solid #b7b8ba;
  border-bottom: 1px solid #b7b8ba;
  ${H4} {
    position: absolute;
    left: 0;
    bottom: 16px;
    margin: 0;
  }
`;
const FormTotalPrice = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #1cabe2;
  text-align: right;
`;

const TitleWithText = styled.div`
  display: flex;
  div {
    padding: 0 0 16px 10px;
    display: flex;
    align-items: center;
  }
`;

// 초기설정값 타입 (step1 스킵용)
export interface SupportInitialValues {
  supportAmount?: string;
  supportAmountInput?: string;
  supportCategory?: string;
  supportTerm?: string;
  solicitCode?: string;
}

export interface SupportStep1Data {
  supportTerm: keyof typeof SUPPORT_TERMS;
  // 저장시에 category와 매핑
  supportCategoryIndex: string;
  supportAmount: string;
  supportAmountInput: string;
}

interface DonateStep1Props {
  onSubmit: (data: SupportStep1Data) => void;
  initialValues: SupportInitialValues;
}

const DonateStep1: FC<DonateStep1Props> = React.memo(
  ({ onSubmit, initialValues }) => {
    // 쿼리스트링에서 넘어온 카테고리 찾기
    const querySupportCategoryIndex = useFindSupportCategory(
      initialValues.supportCategory,
      initialValues.solicitCode,
    )?.indexNumber;
    const {
      register,
      handleSubmit,
      watch,
      setValue,
      clearErrors,
      formState: { errors },
      setError,
      control,
    } = useForm<SupportStep1Data>({
      defaultValues: {
        // 잘못된 값이 넘어오더라도 regular로 처리
        supportTerm:
          initialValues.supportTerm === SUPPORT_TERM_ONCE
            ? SUPPORT_TERM_ONCE
            : SUPPORT_TERM_REGULAR,
        supportAmount: initialValues.supportAmountInput
          ? ``
          : initialValues.supportAmount || '30000',
        supportAmountInput: initialValues.supportAmountInput || ``,
        supportCategoryIndex: querySupportCategoryIndex || ``,
      },
    });

    // 후원코드 후원금액 가져오는 훅
    const useAllSupportCodeAmount = (): GatsbyTypes.SupportCodeAmount[] => {
      const {
        allSupportCodeAmount: { nodes: supportCodeAmount },
      } = useStaticQuery<{
        allSupportCodeAmount: { nodes: GatsbyTypes.SupportCodeAmount[] };
      }>(graphql`
        query {
          allSupportCodeAmount {
            nodes {
              indexNumber
              codeBaseIndexNumber
              categoryCode
              solicitCode
              supportCode
              supportAmount1
              supportAmount2
              supportAmount3
              supportAmount4
              supportAmount5
            }
          }
        }
      `);
      return supportCodeAmount;
    };

    const supportTerm = watch(`supportTerm`);
    const supportAmount = watch(`supportAmount`, `0`);
    const supportAmountInput = watch(`supportAmountInput`);
    const finalAmount = useMemo(
      () => Number(supportAmount || supportAmountInput),
      [supportAmount, supportAmountInput],
    );
    const supportCategories = useSupportCategoriesForPayment(supportTerm);
    const supportCodeAmount = useAllSupportCodeAmount();
    const selectedCategory = watch(`supportCategoryIndex`);

    // 긴급구호 단어가 포함된 경우 SupportPhrase hide
    const isEmergencyCategory = useMemo(() => {
      const emergencyCategory = supportCategories.filter(
        (category) => category.indexNumber === Number(selectedCategory),
      );
      if (emergencyCategory[0]?.codeName?.includes('긴급구호')) {
        return true;
      }
      return false;
    }, [selectedCategory, supportCategories]);

    // 후원금액 설정
    const selectedAmount = useMemo(() => {
      let filteredCodeAmount;

      if (
        initialValues.supportTerm &&
        initialValues.supportCategory &&
        initialValues.solicitCode
      ) {
        filteredCodeAmount = {
          ...supportCodeAmount.filter(
            (code) =>
              code.categoryCode === initialValues.supportTerm &&
              code.supportCode === initialValues.supportCategory &&
              code.solicitCode === initialValues.solicitCode,
          )[0],
        };
      } else {
        filteredCodeAmount = {
          ...supportCodeAmount.filter(
            (code) => code.codeBaseIndexNumber === Number(selectedCategory),
          )[0],
        };
      }

      return { ...filteredCodeAmount };
    }, [
      initialValues.solicitCode,
      initialValues.supportCategory,
      initialValues.supportTerm,
      selectedCategory,
      supportCodeAmount,
    ]);

    // default 후원금액
    const defaultAmount = useMemo(() => {
      // 정기후원
      if (supportTerm === 'FM01') {
        return {
          supportAmount1: 30000,
          supportAmount2: 50000,
          supportAmount3: 100000,
          supportAmount4: 200000,
          supportAmount5: 300000,
        };
      }
      // 일시후원
      if (supportTerm === 'FM02') {
        return {
          supportAmount1: 50000,
          supportAmount2: 100000,
          supportAmount3: 200000,
          supportAmount4: 300000,
          supportAmount5: 500000,
        };
      }
      return {};
    }, [supportTerm]);

    useEffect(() => {
      setValue(
        'supportAmount',
        initialValues.supportAmountInput ||
          initialValues.supportAmount ||
          selectedAmount?.supportAmount1?.toString() ||
          defaultAmount?.supportAmount1?.toString(),
      );
    }, [
      defaultAmount?.supportAmount1,
      initialValues.supportAmount,
      initialValues.supportAmountInput,
      selectedAmount,
      setValue,
    ]);

    const onSubmitForm: SubmitHandler<SupportStep1Data> = useCallback(
      (formData) => {
        if (!finalAmount) {
          setError(`supportAmountInput`, {
            type: `required`,
            message: `후원금액은 필수 입니다`,
            shouldFocus: true,
          });

          return;
        }

        onSubmit(formData);
      },
      [finalAmount, onSubmit, setError],
    );

    const onPressSupportAmount = useCallback(() => {
      setValue(`supportAmountInput`, ``);
      clearErrors(`supportAmountInput`);
    }, [clearErrors, setValue]);

    // 모든 조건이 넘어오면 자동 submit
    useEffect(() => {
      if (
        (initialValues.supportAmount || initialValues.supportAmountInput) &&
        initialValues.supportTerm &&
        initialValues.supportCategory
      ) {
        handleSubmit(onSubmitForm)();
      }
    }, [
      handleSubmit,
      onSubmitForm,
      initialValues.supportAmount,
      initialValues.supportAmountInput,
      initialValues.supportCategory,
      initialValues.supportTerm,
    ]);

    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          {/* <HR color="#1cabe2" /> */}
          <DonateFormItem>
            <H4>후원 방법</H4>
            <InputRadioGroup full>
              {(!initialValues.supportTerm ||
                initialValues.supportTerm === SUPPORT_TERM_REGULAR) && (
                <InputRadio
                  name="supportTerm"
                  ref={register}
                  tab
                  value={SUPPORT_TERM_REGULAR}
                  label="정기"
                />
              )}
              {(!initialValues.supportTerm ||
                initialValues.supportTerm === SUPPORT_TERM_ONCE) && (
                <InputRadio
                  name="supportTerm"
                  ref={register}
                  tab
                  value={SUPPORT_TERM_ONCE}
                  label="일시"
                />
              )}
            </InputRadioGroup>
          </DonateFormItem>
          <DonateFormItem>
            <H4>후원 분야</H4>
            <Select
              readOnly={initialValues.supportCategory !== undefined}
              name="supportCategoryIndex"
              ref={register({
                required: {
                  value: true,
                  message: `필수 입력입니다`,
                },
              })}
            >
              <option value="">후원 분야 선택</option>
              {supportCategories.map((category) => (
                <option key={category.indexNumber} value={category.indexNumber}>
                  {category.codeName}
                </option>
              ))}
            </Select>
            {errors.supportCategoryIndex && (
              <ErrorMessage>{errors.supportCategoryIndex.message}</ErrorMessage>
            )}
          </DonateFormItem>
          <DonateFormItem
            css={`
              margin-bottom: 56px;
            `}
          >
            <TitleWithText>
              <H4>후원 금액(원)</H4>
              <div>※ 원하시는 금액을 선택해 주세요</div>
            </TitleWithText>

            <>
              <InputRadioGroup
                full
                css={`
                  margin-bottom: -1px;
                `}
              >
                <InputRadio
                  name="supportAmount"
                  ref={register}
                  onFocus={onPressSupportAmount}
                  tab
                  value={
                    selectedAmount?.supportAmount1 ||
                    defaultAmount?.supportAmount1
                  }
                  label={
                    (selectedAmount.supportAmount1 &&
                      selectedAmount.supportAmount1?.toLocaleString()) ||
                    defaultAmount.supportAmount1?.toLocaleString() ||
                    ''
                  }
                />
                <InputRadio
                  name="supportAmount"
                  ref={register}
                  onFocus={onPressSupportAmount}
                  tab
                  value={
                    selectedAmount?.supportAmount2 ||
                    defaultAmount?.supportAmount2
                  }
                  label={
                    (selectedAmount.supportAmount2 &&
                      selectedAmount.supportAmount2?.toLocaleString()) ||
                    defaultAmount.supportAmount2?.toLocaleString() ||
                    ''
                  }
                />
                <InputRadio
                  name="supportAmount"
                  ref={register}
                  onFocus={onPressSupportAmount}
                  tab
                  value={
                    selectedAmount?.supportAmount3 ||
                    defaultAmount?.supportAmount3
                  }
                  label={
                    (selectedAmount.supportAmount3 &&
                      selectedAmount.supportAmount3?.toLocaleString()) ||
                    defaultAmount.supportAmount3?.toLocaleString() ||
                    ''
                  }
                />
              </InputRadioGroup>
              <InputRadioGroup full>
                <InputRadio
                  name="supportAmount"
                  ref={register}
                  onFocus={onPressSupportAmount}
                  tab
                  value={
                    selectedAmount?.supportAmount4 ||
                    defaultAmount?.supportAmount4
                  }
                  label={
                    (selectedAmount.supportAmount4 &&
                      selectedAmount.supportAmount4?.toLocaleString()) ||
                    defaultAmount.supportAmount4?.toLocaleString() ||
                    ''
                  }
                />
                <InputRadio
                  name="supportAmount"
                  ref={register}
                  onFocus={onPressSupportAmount}
                  tab
                  value={
                    selectedAmount?.supportAmount5 ||
                    defaultAmount?.supportAmount5
                  }
                  label={
                    (selectedAmount.supportAmount5 &&
                      selectedAmount.supportAmount5?.toLocaleString()) ||
                    defaultAmount.supportAmount5?.toLocaleString() ||
                    ''
                  }
                />
                <Controller
                  control={control}
                  name="supportAmountInput"
                  rules={{
                    validate: (value) => {
                      if (supportAmount) {
                        return true;
                      }
                      const val = Number(value);
                      if (supportTerm === SUPPORT_TERM_REGULAR) {
                        if (val < 10000) {
                          return `1만원 이상부터 정기후원이 가능합니다`;
                        }
                      }
                      if (val >= 10000000) {
                        return `고액 정기후원은 후원본부(02-737-1004)로 연락주시기 바랍니다`;
                      }
                      return true;
                    },
                  }}
                  as={
                    <InputNumber
                      onFocus={() => setValue(`supportAmount`, ``)}
                      placeholder="금액 직접 입력"
                      style={{
                        borderTop: `1px solid #1cabe2`,
                        borderLeft: `none`,
                      }}
                      allowNegative={false}
                    />
                  }
                />
              </InputRadioGroup>
            </>

            {errors.supportAmountInput && (
              <ErrorMessage>{errors.supportAmountInput.message}</ErrorMessage>
            )}
            {supportTerm === SUPPORT_TERM_REGULAR && !isEmergencyCategory && (
              <FormTip type="blue" className="support-textbox">
                <SupportPhrase supportAmount={finalAmount} />
              </FormTip>
            )}
            <FormTotal>
              <H4>총 후원금액</H4>
              <FormTotalPrice>{finalAmount.toLocaleString()}원</FormTotalPrice>
            </FormTotal>
            <Button full type="submit">
              다음
            </Button>
          </DonateFormItem>
        </form>
      </FormContainer>
    );
  },
);

export default DonateStep1;
