import { DonateFormItem, LabelFlex } from './DonateCommon';
import { SupportStep1Data } from './DonateStep1';
import { FormButton, RightCol } from '../auth/AuthCommon';
import { RegularPayInfoVo } from '@/__generated__/FrontApi';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import HR from '@/components/HR';
import AddressButton from '@/components/Input/AddressButton';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputDate from '@/components/Input/InputDate';
import InputNumber from '@/components/Input/InputNumber';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import InputTextarea from '@/components/Input/InputTextarea';
import PostTooltip from '@/components/PostTooltip';
import Select from '@/components/Select';
import { NotiBox, NotiItem, NotiList } from '@/components/Text/TextNoti';
import NiceAuthenticationForm from '@/components/ThirdParty/NiceAuthenticationForm';
import SirenNameCheck, {
  NameCheckMemberInfo,
} from '@/components/ThirdParty/SirenNameCheck';
import { H4 } from '@/components/Titles';
import ToggleDiv from '@/components/ToggleDiv';
import { getFullBirthday } from '@/helpers/StringHelper';
import {
  ENUM_SUPPORTER_TYPE,
  MEMBER_TYPES,
  TYPE_MEMBER_TYPES,
  SUPPORT_CATEGORY_ONCE_MEMORY,
} from '@/helpers/SupportHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { breakpoint } from '@/helpers/BreakpointHelper';
import InputNumberMasking from '@/components/Input/InputNumberMasking';
import { NONAME } from 'dns';

export interface SupportStep2Data {
  supporterType: ENUM_SUPPORTER_TYPE;
  memberTypeCode: keyof TYPE_MEMBER_TYPES;
  memberName: string;
  bankingOwnerRelation: string;
  juminNumber1: string;
  juminNumber2: string;
  gender: string;
  juminEnc: string;
  // 사업자등록번호
  businessNumber: string;
  // 법정대리인 이름
  relationName: string;
  // 법정대리인 관계
  relationType: string;
  relationTelNumber: string;
  agreeReceipt: boolean;
  mobilePhoneNumber: string;
  email: string;
  birthday: string;
  // 마케팅정보 수신여부
  mobileAgree: boolean;
  emailAgree: boolean;
  postAgree: boolean;
  zipCode: string;
  address: string;
  addressDetail: string;
  // 기념일 관련
  anniType: string;
  anniDate: string;
  story: string;
  honor: string;
  // 법인/단체 담당자 관련
  contact: string;
  managerName: string;
  managerEmail: string;
}

const DonateFormItem2 = styled(DonateFormItem)`
  padding-bottom: 24px;
`;

const FormatJumin = styled.div`
  width: auto;
  input {
    border: 0px;
    :focus {
      border: 0px;
    }
  }

  border: 1px solid #e5e6e8;
  :focus-within {
    border: 1px solid #2589c9;
  }

  .hypen {
    margin-left: 24px;
    ${breakpoint(425)} {
      margin-left: 14px;
    }
    ${breakpoint(375)} {
      margin-left: 10px;
    }
    ${breakpoint(363)} {
      margin-left: 1px;
    }
  }

  input:nth-child(1) {
    ${breakpoint(400)} {
      padding-right: 0px;
      width: 105px;
    }
    ${breakpoint(337)} {
      padding-left: 1px;
      width: 88px;
    }
  }

  input:nth-child(3) {
    ${breakpoint(400)} {
      padding-right: 0px;
      width: 105px;
    }
    ${breakpoint(375)} {
      padding-left: 10px;
    }
    ${breakpoint(363)} {
      padding-left: 1px;
      width: 88px;
    }
  }

  .juminNumber {
    display: inline;
    width: 150px;
    padding: 0 16px;
    ${breakpoint(`mobile`)} {
      width: 120px;
    }
  }
`;

interface DonateStep2Props {
  onSubmit: (data: SupportStep2Data) => void;
  step1Data?: SupportStep1Data;
  queryParams: any;
  onLoadRegularPayInfo: (data: RegularPayInfoVo) => void;
  selectedCategory?: GatsbyTypes.SupportCategory;
}

const DonateStep2: FC<DonateStep2Props> = observer(
  ({
    onSubmit: originalOnSubmit,
    step1Data,
    queryParams,
    onLoadRegularPayInfo,
    selectedCategory,
  }) => {
    const popupStore = usePopupStore();
    const userStore = useUserStore();
    const [showTooltip, setShowTooltip] = useState(false);
    // 실명인증 완료 여부
    const [isNameCheckValid, setIsNameCheckValid] = useState<boolean>(false);
    // 실명인증후 넘어오는 사용자정보
    const [memberInfo, setMemberInfo] = useState<NameCheckMemberInfo>();
    // 본인인증
    const [isValidRelationPhone, setIsValidRelationPhone] = useState(false);

    const [anniversaries, setAnniversaries] = useState([
      { key: '', value: '기념일 종류 선택' },
      { key: '01', value: '출생' },
      { key: '02', value: '백일' },
      { key: '03', value: '생일' },
      { key: '04', value: '수상' },
      { key: '05', value: '승진' },
      { key: '06', value: '회갑' },
      { key: '07', value: '결혼' },
      { key: '08', value: '연애' },
      { key: '09', value: '추모' },
      { key: '10', value: '기타' },
    ]);
    const [selectedAnniType, setSelectedAnniType] = useState(
      queryParams.atype || '',
    );

    const {
      register,
      handleSubmit,
      watch,
      control,
      setValue,
      clearErrors,
      formState: { errors },
      getValues,
      reset,
    } = useForm<SupportStep2Data>({
      // 가변 defaultValues
      defaultValues: {
        supporterType:
          // default는 개인
          queryParams.supporterType === ENUM_SUPPORTER_TYPE.group
            ? ENUM_SUPPORTER_TYPE.group
            : ENUM_SUPPORTER_TYPE.person,
        memberName: ``,
        memberTypeCode: queryParams.memberTypeCode,
        birthday: ``,
        mobilePhoneNumber: ``,
        juminNumber1: ``,
        juminNumber2: ``,
        juminEnc: ``,
        gender: ``,
        contact: ``,
      },
    });
    // 불러온 후원자 정보가 있는지 여부
    const isDonor = useMemo(() => userStore.isDonor(), [userStore]);
    const businessNumber = watch(`businessNumber`);

    useEffect(() => {
      // 사용자 정보가 로딩되면 (로그인 상태면) 기본값 세팅
      if (userStore.user) {
        const phoneNumber =
          userStore.user.mobilePhoneNumber &&
          `${userStore.user.mobilePhoneNumber}`.replace(/-/g, ``);
        const defaultValues: Partial<SupportStep2Data> = {
          supporterType: [
            ENUM_SUPPORTER_TYPE.group,
            ENUM_SUPPORTER_TYPE.corporation,
          ].includes(userStore.user.donorType)
            ? ENUM_SUPPORTER_TYPE.group
            : ENUM_SUPPORTER_TYPE.person,
          // 공통
          memberName: userStore.user.name,
          email: userStore.user.email,
          mobilePhoneNumber: phoneNumber,
          memberTypeCode: userStore.user.donorTypeCode,
          address: userStore.user.address1,
          addressDetail: userStore.user.address2,
          zipCode: userStore.user.zip,
          mobileAgree: userStore.isSmsAgree(),
          emailAgree: userStore.isEmailAgree(),
          postAgree: userStore.isPostAgree(),
          birthday: userStore.getFullBirthday() || '',
          juminNumber1: ``,
          juminNumber2: ``,
          gender: ``,
        };
        // 개인일때
        if (userStore.isIndividualUser()) {
          defaultValues.gender = userStore.user.gender || '';
          // 주민번호 있다면
          if (userStore.user.juminnoLength) {
            defaultValues.juminNumber1 = userStore.user.juminno.substring(0, 6);
            if (userStore.user.juminnoLength === 13) {
              defaultValues.juminNumber2 = userStore.user.juminno.substring(7);
              defaultValues.agreeReceipt = true;
            }
            // 실명인증 완료처리
            if (userStore.isValidJuminno()) {
              setIsNameCheckValid(true);
            } else {
              setIsNameCheckValid(false);
            }
          }
        }
        // 법인, 단체일때
        else {
          defaultValues.memberTypeCode = userStore.user.donorTypeCode;
          defaultValues.contact = phoneNumber;
          defaultValues.managerEmail = userStore.user.email;
          defaultValues.businessNumber = userStore.user.businessNumber; // 사업자번호
        }

        // 기본값 덮어쓰기
        reset(defaultValues);
      }
    }, [reset, setValue, userStore, userStore.user]);

    useEffect(() => {
      // const queryData = qs.parse(location.search);
      // const { atype } = queryData;
      // if (atype) {
      //   setValue('anniType', '추모');
      // }
      // console.log(atype);
    }, []);

    const supporterType = watch(`supporterType`);
    const memberTypeCode = watch(`memberTypeCode`);
    const agreeReceipt = watch(`agreeReceipt`);
    const postAgree = watch(`postAgree`);

    const birthdayInput = watch(`birthday`);
    // 생일을 dayjs 인스턴스로 변환
    const birthday = useMemo(
      () =>
        birthdayInput?.length === 8 &&
        dayjs(birthdayInput, `YYYYMMDD`, true).isValid()
          ? dayjs(birthdayInput, `YYYYMMDD`, true)
          : null,
      [birthdayInput],
    );
    const age = useMemo(
      () => birthday && dayjs().diff(birthday, `year`, true),
      [birthday],
    );

    // 14세 미만 여부
    const isUnderFourteen = age !== null && age < 14 && age >= 0;

    // 우편 신청시 기본값 세팅
    useEffect(() => {
      if (postAgree && memberInfo) {
        setValue(`zipCode`, memberInfo.zipCode);
        setValue(`address`, memberInfo.address);
        setValue(`addressDetail`, memberInfo.addressDetail);
      }
    }, [memberInfo, postAgree, setValue]);

    // 실명인증후 회원정보 넘어온것 세팅
    useEffect(() => {
      if (memberInfo) {
        setValue(`email`, memberInfo.email);
        setValue(
          `mobilePhoneNumber`,
          memberInfo.mobilePhoneNumber &&
            `${memberInfo.mobilePhoneNumber}`.replace(/-/g, ``),
        );
        setValue(`mobileAgree`, memberInfo.mailSms === `Y`);
        setValue(`emailAgree`, memberInfo.mailEdm === `Y`);
        setValue(`postAgree`, memberInfo.mailDm === `Y`);
        setValue(`birthday`, userStore.getFullBirthday());
      }
    }, [getValues, memberInfo, setValue]);

    // 기부금 영수증 체크 해제인 경우, 주민번호 값 전송되는 부분 해결을 위한 별도의 submit 생성
    const onSubmit = useCallback(
      (data: SupportStep2Data) => {
        const { ...rest } = data;
        console.log('onSubmit > data > ', data);
        console.log('onSubmit > rest > ', rest);
        originalOnSubmit({
          ...rest,
        });
      },
      [originalOnSubmit],
    );

    const getByteLength = (s, b = 0, i = 0, c = 0) => {
      for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 2 : 1);
      return b;
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <HR
          color="#1cabe2"
          css={`
            margin-bottom: 24px;
          `}
        />
        <DonateFormItem2>
          <FormGroup noMargin>
            <H4>후원자 유형</H4>
            <Row>
              <InputRadioGroup full>
                <InputRadio
                  name="supporterType"
                  ref={register}
                  tab
                  value={ENUM_SUPPORTER_TYPE.person}
                  label="개인"
                  defaultChecked
                  // 법인/단체에서 다시 개인 누를때 실명인증 초기화
                  onClick={() => setIsNameCheckValid(false)}
                  readOnly={isDonor}
                />
                <InputRadio
                  name="supporterType"
                  ref={register}
                  tab
                  value={ENUM_SUPPORTER_TYPE.group}
                  label="법인/단체"
                  readOnly={isDonor}
                />
              </InputRadioGroup>
            </Row>
            {supporterType === ENUM_SUPPORTER_TYPE.group ? (
              <>
                <Row>
                  <Select
                    name="memberTypeCode"
                    ref={register({
                      required: {
                        value: true,
                        message: `필수 입력입니다`,
                      },
                    })}
                    readOnly={isDonor}
                  >
                    <option value="">종류 선택</option>
                    {/* 법인 옵션 */}
                    {Object.entries(
                      MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation],
                    ).map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                    {/* 단체 옵션 */}
                    {Object.entries(
                      MEMBER_TYPES[ENUM_SUPPORTER_TYPE.group],
                    ).map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                </Row>
                {errors.memberTypeCode && (
                  <ErrorMessage>{errors.memberTypeCode.message}</ErrorMessage>
                )}
              </>
            ) : null}
          </FormGroup>
        </DonateFormItem2>
        {/* 후원자 유형 : 개인 */}
        {supporterType === ENUM_SUPPORTER_TYPE.person && (
          <>
            <DonateFormItem2
              noBorder
              css={`
                padding-bottom: 0px;
              `}
            >
              <H4>후원자 정보</H4>
              <FormGroup>
                <LabelText require>성명</LabelText>
                <InputText
                  placeholder="성명 입력"
                  name="memberName"
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                    validate: {
                      isValidRange: (value) => {
                        if (getByteLength(value) > 30)
                          return `제한 길이가 초과되었습니다.(최대 한글 15자)`;
                      },
                    },
                  })}
                  readOnly={isNameCheckValid || isDonor}
                />
                {errors.memberName && (
                  <ErrorMessage>{errors.memberName.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Row>
                  <LabelText>기부금 영수증</LabelText>
                </Row>
                <Row
                  css={`
                    margin: 8px 0 0 0;
                  `}
                >
                  <InputCheckbox
                    label="신청"
                    name="agreeReceipt"
                    ref={register}
                    readOnly={isNameCheckValid}
                  />
                </Row>
                <ToggleDiv active={agreeReceipt}>
                  <Row>
                    <Col desktop="auto">
                      <input type="hidden" name="gender" ref={register} />
                      <input type="hidden" name="juminEnc" ref={register} />
                      <FormatJumin
                        style={
                          isNameCheckValid ? { background: '#f8f9fa' } : {}
                        }
                      >
                        <Controller
                          control={control}
                          name="juminNumber1"
                          rules={
                            (agreeReceipt && {
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                              validate: (value) =>
                                dayjs(value, `YYMMDD`, true).isValid() ||
                                `주민등록번호 자릿수를 확인하세요.`,
                            }) ||
                            undefined
                          }
                          as={
                            <InputNumber
                              format="######"
                              placeholder="주민번호 앞자리"
                              readOnly={isNameCheckValid}
                              className="juminNumber"
                              autoComplete="off"
                              style={{ border: '0px none' }}
                            />
                          }
                        />
                        <span className="hypen">-</span>
                        <Controller
                          control={control}
                          name="juminNumber2"
                          rules={
                            (agreeReceipt && {
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                              validate: (value) => {
                                if (value.length !== 7) {
                                  return `주민등록번호 자릿수를 확인하세요.`;
                                }
                                if (value && !isNameCheckValid) {
                                  return `실명인증이 필요합니다`;
                                }
                                return;
                              },
                            }) ||
                            undefined
                          }
                          as={
                            isNameCheckValid ? (
                              <InputText
                                format="#######"
                                placeholder="주민번호 뒷자리"
                                readOnly={isNameCheckValid}
                                className="juminNumber"
                                autoComplete="off"
                                style={{ border: '0px none' }}
                                type="password"
                              />
                            ) : (
                              <InputNumberMasking
                                format="#######"
                                placeholder="주민번호 뒷자리"
                                readOnly={isNameCheckValid}
                                className="juminNumber"
                                autoComplete="off"
                                style={{ border: '0px none' }}
                              />
                            )
                          }
                        />
                      </FormatJumin>
                    </Col>
                    <RightCol className="juminRight">
                      {isNameCheckValid ? (
                        <FormButton color="grey" disabled>
                          인증완료
                        </FormButton>
                      ) : (
                        <SirenNameCheck
                          getParams={() => ({
                            name: getValues(`memberName`),
                            jumin:
                              getValues(`juminNumber1`) +
                              '' +
                              getValues(`juminNumber2`),
                            isNameCheckValid:
                              userStore.isIndividualUser() &&
                              userStore.isValidJuminno(),
                          })}
                          onSuccess={(result) => {
                            console.log('>> certInfo', result.certInfo);
                            // console.log(getValues(`juminNumber1`).substring(0, 6));
                            // 주민등록번호 잘라서 생년월일 세팅
                            setValue(
                              `birthday`,
                              getFullBirthday(
                                getValues(`juminNumber1`) +
                                  '' +
                                  getValues(`juminNumber2`),
                              ),
                            );
                            setValue(`gender`, result.certInfo?.gender);
                            setValue(`juminEnc`, result.certInfo?.juminEnc);
                            setIsNameCheckValid(true);
                            clearErrors(`gender`);
                            clearErrors(`juminEnc`);
                            // 실명인증 성공시 비로그인 상태고
                            if (!userStore.isLoggedIn()) {
                              // 정기후원정보가 넘어오면 최근 후원정보 가져올지 확인
                              // 2021-09-09 1651610223 비로그인+최근후원정보 불러오기 기능 비활성 요청에 의해 처리
                              // if (result.payInfo.paymentType) {
                              //   popupStore.show(
                              //     `최근 후원정보를 사용하시겠습니까?`,
                              //     {
                              //       onConfirm: (ok) => {
                              //         if (ok) {
                              //           // 회원정보 있다면 세팅
                              //           if (result.memberInfo.email) {
                              //             setMemberInfo(result.memberInfo);
                              //           }
                              //           onLoadRegularPayInfo(result.payInfo);
                              //         }
                              //       },
                              //       showCancelButton: true,
                              //     },
                              //   );
                              // }
                            }
                          }}
                          onError={({ resultMessage }) => {
                            if (resultMessage.includes('3')) {
                              popupStore.show(
                                `${resultMessage}\nsiren24.com 홈페이지에서 실명 등록이 필요합니다. (문의: 1577-1006)`,
                              );
                            } else {
                              popupStore.show(resultMessage);
                            }
                          }}
                          renderSubmitButton={(buttonProps) => (
                            <FormButton outline {...buttonProps}>
                              실명인증
                            </FormButton>
                          )}
                        />
                      )}
                    </RightCol>
                  </Row>
                  {(errors.juminNumber1 || errors.juminNumber2) && (
                    <ErrorMessage>
                      {errors.juminNumber1?.message ||
                        errors.juminNumber2?.message}
                    </ErrorMessage>
                  )}
                  <Row>
                    <FormTextTip>
                      입력하신 주민등록번호는{` `}
                      <strong>국세청 기부금 영수증 발급 대행</strong>을 위해
                      사용됩니다.
                    </FormTextTip>
                  </Row>
                </ToggleDiv>
              </FormGroup>
              {/* 로그인정보중 생년월일이 있거나, 실명인증시 hidden */}
              <ToggleDiv
                active={
                  (!userStore.getFullBirthday() ||
                    userStore.getFullBirthday()?.length !== 8) &&
                  !isNameCheckValid
                }
              >
                <FormGroup noMargin>
                  <LabelText require>생년월일</LabelText>
                  <Row>
                    <Controller
                      control={control}
                      name="birthday"
                      rules={{
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                        validate: (value) =>
                          dayjs(value, `YYYYMMDD`, true).isValid() ||
                          `올바르지 않은 형식입니다 (8자리입력. YYYYMMDD)`,
                      }}
                      as={
                        <InputNumber
                          format="########"
                          placeholder="생년월일 8자리 입력(YYYYMMDD)"
                        />
                      }
                    />
                  </Row>
                  {errors.birthday && (
                    <ErrorMessage>{errors.birthday.message}</ErrorMessage>
                  )}
                  <Row>
                    <FormTextTip>
                      만 14세 미만의 경우, 법정대리인 인증이 완료되어야 후원이
                      가능합니다.
                    </FormTextTip>
                  </Row>
                </FormGroup>
              </ToggleDiv>
            </DonateFormItem2>
            {/* 14세 미만일때 법정대리인 정보 입력 */}
            {isUnderFourteen && (
              <DonateFormItem2
                bgGray
                noBorder
                css={`
                  margin-top: 24px;
                `}
              >
                <H4>법정대리인 정보</H4>
                <FormGroup>
                  <LabelText require>법정대리인 성명</LabelText>
                  <InputText
                    name="relationName"
                    ref={register({
                      required: {
                        value: true,
                        message: `필수 입력입니다`,
                      },
                    })}
                    placeholder="성명 입력"
                    readOnly={isValidRelationPhone}
                  />
                  {errors.relationName && (
                    <ErrorMessage>{errors.relationName.message}</ErrorMessage>
                  )}
                </FormGroup>
                <FormGroup>
                  <LabelText require>후원자와의 관계</LabelText>
                  <Select
                    name="relationType"
                    ref={register({
                      required: {
                        value: true,
                        message: `필수 입력입니다`,
                      },
                    })}
                  >
                    <option value="">관계 선택</option>
                    <option>부모</option>
                    <option>친척</option>
                    <option>지인</option>
                    <option>선생님</option>
                    <option>기타</option>
                  </Select>
                  {errors.relationType && (
                    <ErrorMessage>{errors.relationType.message}</ErrorMessage>
                  )}
                </FormGroup>
                <FormGroup noMargin>
                  <LabelText require>법정대리인 휴대폰번호</LabelText>
                  <Row className="certify">
                    <div className="col-left">
                      {isValidRelationPhone ? (
                        <FormButton outline color="grey" disabled>
                          인증완료
                        </FormButton>
                      ) : (
                        <NiceAuthenticationForm
                          onSuccess={(result) => {
                            setIsValidRelationPhone(true);
                            setValue(`relationTelNumber`, result.mobile);
                            clearErrors(`relationTelNumber`);
                          }}
                          renderSubmitButton={(props) => (
                            <FormButton outline {...props}>
                              본인인증
                            </FormButton>
                          )}
                        />
                      )}
                    </div>
                    <div className="col-right">
                      <Controller
                        control={control}
                        name="relationTelNumber"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                          pattern: {
                            value: mobileRegex,
                            message: `올바른 휴대폰번호가 아닙니다`,
                          },
                          validate: {
                            isValidRange: (value) =>
                              (value.substr(0, 3) === `010` &&
                                value.length === 11) ||
                              (value.substr(0, 3) !== `010` &&
                                value.length === 10) ||
                              `휴대폰번호 자릿수를 확인해주세요`,
                          },
                        }}
                        as={
                          <InputNumber
                            format="###########"
                            placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                            readOnly
                          />
                        }
                      />
                    </div>
                  </Row>
                  {errors.relationTelNumber && (
                    <ErrorMessage>
                      {errors.relationTelNumber.message}
                    </ErrorMessage>
                  )}
                </FormGroup>
              </DonateFormItem2>
            )}
            <DonateFormItem2 noBorder>
              <FormGroup>
                <LabelText require>휴대폰번호</LabelText>
                <Controller
                  control={control}
                  name="mobilePhoneNumber"
                  rules={{
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                    pattern: {
                      value: mobileRegex,
                      message: `올바른 휴대폰번호가 아닙니다`,
                    },
                    validate: {
                      isValidRange: (value) =>
                        (value.substr(0, 3) === `010` && value.length === 11) ||
                        (value.substr(0, 3) !== `010` && value.length === 10) ||
                        `휴대폰번호 자릿수를 확인해주세요`,
                    },
                  }}
                  as={
                    <InputNumber
                      format="###########"
                      placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                    />
                  }
                />
                {errors.mobilePhoneNumber && (
                  <ErrorMessage>
                    {errors.mobilePhoneNumber.message}
                  </ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <LabelText require>이메일</LabelText>
                <InputText
                  name="email"
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                    pattern: {
                      value: emailRegex,
                      message: `올바르지 않은 이메일주소 입니다`,
                    },
                  })}
                  placeholder="이메일주소 입력"
                />
                {errors.email && (
                  <ErrorMessage>{errors.email.message}</ErrorMessage>
                )}
              </FormGroup>
            </DonateFormItem2>
          </>
        )}
        {/* 후원자 유형 : 법인/단체 */}
        {supporterType === ENUM_SUPPORTER_TYPE.group && (
          <DonateFormItem2 noBorder>
            <H4>후원자 정보</H4>
            {/* 법인의 경우 */}
            {Object.keys(
              MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation],
            ).includes(memberTypeCode) ? (
              <>
                <FormGroup>
                  <LabelText require>법인명</LabelText>
                  <InputText
                    placeholder="법인명 입력"
                    name="memberName"
                    ref={register({
                      required: {
                        value: true,
                        message: `필수 입력입니다`,
                      },
                      validate: {
                        isValidRange: (value) => {
                          if (getByteLength(value) > 30)
                            return `제한 길이가 초과되었습니다.(최대 한글 15자)`;
                        },
                      },
                    })}
                    readOnly={isDonor}
                  />
                  {errors.memberName && (
                    <ErrorMessage>{errors.memberName.message}</ErrorMessage>
                  )}
                </FormGroup>
                <FormGroup>
                  <LabelText require>사업자번호</LabelText>
                  <Controller
                    control={control}
                    name="businessNumber"
                    rules={{
                      required: {
                        value: true,
                        message: `필수 입력입니다`,
                      },
                    }}
                    as={
                      <InputNumber
                        format="##########"
                        placeholder="사업자번호 입력(&lsquo;-&rsquo; 제외)"
                        readOnly={isDonor && !!businessNumber}
                      />
                    }
                  />
                  {errors.businessNumber && (
                    <ErrorMessage>{errors.businessNumber.message}</ErrorMessage>
                  )}
                </FormGroup>
              </>
            ) : (
              <FormGroup>
                <LabelText require>단체명</LabelText>
                <InputText
                  placeholder="단체명 입력"
                  name="memberName"
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                    validate: {
                      isValidRange: (value) => {
                        if (getByteLength(value) > 30)
                          return `제한 길이가 초과되었습니다.(최대 한글 15자)`;
                      },
                    },
                  })}
                  readOnly={isDonor}
                />
                {errors.memberName && (
                  <ErrorMessage>{errors.memberName.message}</ErrorMessage>
                )}
              </FormGroup>
            )}
            <FormGroup>
              <LabelText require>연락처</LabelText>
              <Controller
                control={control}
                name="contact"
                rules={{
                  required: {
                    value: true,
                    message: `필수 입력입니다`,
                  },
                }}
                as={
                  <InputNumber
                    format="###########"
                    placeholder="연락처 입력(&lsquo;-&rsquo; 제외)"
                  />
                }
              />
              {errors.contact && (
                <ErrorMessage>{errors.contact.message}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup>
              <LabelText require>담당자명</LabelText>
              <InputText
                name="managerName"
                ref={register({
                  required: {
                    value: true,
                    message: `필수 입력입니다`,
                  },
                })}
                placeholder="담당자명 입력"
              />
              {errors.managerName && (
                <ErrorMessage>{errors.managerName.message}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup>
              <LabelText require>담당자 이메일</LabelText>
              <InputText
                name="managerEmail"
                ref={register({
                  required: {
                    value: true,
                    message: `필수 입력입니다`,
                  },
                  pattern: {
                    value: emailRegex,
                    message: `올바르지 않은 이메일주소 입니다`,
                  },
                })}
                placeholder="이메일주소 입력"
              />
              {errors.managerEmail && (
                <ErrorMessage>{errors.managerEmail.message}</ErrorMessage>
              )}
            </FormGroup>
            {/* 단체일때 주소 입력 필수 */}
            <FormGroup>
              <LabelText require>주소</LabelText>
              <Row>
                <Col desktop="auto">
                  <InputText
                    name="zipCode"
                    ref={register({
                      required: {
                        value: true,
                        message: `필수 입력입니다`,
                      },
                    })}
                    readOnly
                  />
                </Col>
                <RightCol desktop="none">
                  <AddressButton
                    onSelectAddress={(address) => {
                      setValue(`zipCode`, address.zonecode);
                      setValue(`address`, address.address);
                    }}
                    renderButton={(buttonProps) => (
                      <FormButton {...buttonProps}>주소검색</FormButton>
                    )}
                  />
                </RightCol>
              </Row>
              <Row>
                <InputText name="address" ref={register} readOnly />
              </Row>
              <Row>
                <InputText name="addressDetail" ref={register} />
              </Row>
              {errors.zipCode && (
                <ErrorMessage>{errors.zipCode.message}</ErrorMessage>
              )}
            </FormGroup>
          </DonateFormItem2>
        )}

        {/* 후원분야가 기념기부인 경우 */}
        {selectedCategory?.code === SUPPORT_CATEGORY_ONCE_MEMORY && (
          <>
            <HR />
            <DonateFormItem2>
              <FormGroup>
                <H4>기념일 정보</H4>
                <LabelText require>기념일 종류</LabelText>
                <Select
                  readOnly={queryParams.atype || false}
                  name="anniType"
                  ref={register({
                    validate: (value) => {
                      if (value === '기념일 종류 선택') {
                        return `필수 입력입니다`;
                      }
                      return true;
                    },
                  })}
                  onChange={(e) => setSelectedAnniType(e.target.value)}
                >
                  {(queryParams.atype
                    ? anniversaries.filter((a) => a.key === queryParams.atype)
                    : anniversaries
                  ).map((a) => (
                    <option key={a.key} value={a.value}>
                      {a.value}
                    </option>
                  ))}
                </Select>
                {errors.anniType && (
                  <ErrorMessage>{errors.anniType.message}</ErrorMessage>
                )}
              </FormGroup>

              <FormGroup className="t2">
                <LabelText require>기념일</LabelText>
                <InputDate
                  name="anniDate"
                  initialDate={queryParams.anniDate}
                  readOnly={queryParams.anniDate || false}
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                />
                {errors.anniDate && (
                  <ErrorMessage>{errors.anniDate.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup className="t2">
                <LabelText require>사연</LabelText>
                <InputTextarea
                  placeholder="기념일 사연을 입력해주세요."
                  name="story"
                  defaultValue={queryParams.story || ''}
                  readOnly={queryParams.story || false}
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                />
                {errors.story && (
                  <ErrorMessage>{errors.story.message}</ErrorMessage>
                )}
              </FormGroup>

              {/* 기념일 종류가 추모인 경우 */}
              {selectedAnniType === '09' && (
                <FormGroup className="t1">
                  <LabelText require>후원예우</LabelText>
                  <Row
                    css={`
                      position: relative;
                    `}
                  >
                    <span
                      css={`
                        position: absolute;
                        left: 13px;
                      `}
                    >
                      故
                    </span>
                    <InputText
                      name="honor"
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                      })}
                      css={`
                        padding-left: 32px;
                      `}
                    />
                  </Row>
                  {errors.honor && (
                    <ErrorMessage>{errors.honor.message}</ErrorMessage>
                  )}
                  <Row>
                    <FormTextTip>
                      기억하고 싶은 존재의 이름을 입력해주세요.
                    </FormTextTip>
                  </Row>
                </FormGroup>
              )}
            </DonateFormItem2>
          </>
        )}
        <DonateFormItem noBorder>
          <FormGroup>
            <LabelText>
              전 세계 어린이를 위한 소식·후원·캠페인 안내 수신동의(선택)
            </LabelText>
            <LabelFlex>
              <li>
                <InputCheckbox
                  label="모바일"
                  name="mobileAgree"
                  ref={register}
                />
              </li>
              <li>
                <InputCheckbox
                  label="이메일"
                  name="emailAgree"
                  ref={register}
                />
              </li>
              <li style={{ position: `relative` }}>
                <InputCheckbox
                  name="postAgree"
                  label="우편"
                  ref={register}
                  onClick={(e) => setShowTooltip(e.currentTarget.checked)}
                />
                {showTooltip && (
                  <PostTooltip
                    onRequestClose={(isDigitalSelected) => {
                      if (isDigitalSelected) {
                        setValue(`postAgree`, false);
                        setValue(`mobileAgree`, true);
                        setValue(`emailAgree`, true);
                      }
                      setShowTooltip(false);
                    }}
                  />
                )}
              </li>
            </LabelFlex>
          </FormGroup>
          <NotiBox className="FormGroup last">
            <NotiList>
              <NotiItem>
                <strong>
                  공지사항, 긴급구호 및 회원관리 안내는 수신 여부와 관계없이
                  발송됩니다.
                </strong>
              </NotiItem>
              <NotiItem>
                <strong>
                  모바일을 선택하시면 가장 빠르게 소식을 받아 보실 수 있습니다.
                </strong>
              </NotiItem>
              <NotiItem>
                동의 내용은 언제든지 웹사이트 또는 전화상담을 통해 변경이
                가능합니다.
              </NotiItem>
              <NotiItem>
                기존 후원자의 경우 새로 선택하신 동의 내용으로 변경됩니다.
              </NotiItem>
            </NotiList>
          </NotiBox>
        </DonateFormItem>
        {/* 개인일때, 우편 신청시 주소 입력 */}
        {supporterType === ENUM_SUPPORTER_TYPE.person && postAgree && (
          <FormGroup>
            <LabelText require>주소</LabelText>
            <Row>
              <Col desktop="auto">
                <InputText
                  name="zipCode"
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                  readOnly
                />
              </Col>
              <RightCol desktop="none">
                <AddressButton
                  onSelectAddress={(address) => {
                    setValue(`zipCode`, address.zonecode);
                    setValue(`address`, address.address);
                  }}
                  renderButton={(buttonProps) => (
                    <FormButton {...buttonProps}>주소검색</FormButton>
                  )}
                />
              </RightCol>
            </Row>
            <Row>
              <InputText name="address" ref={register} readOnly />
            </Row>
            <Row>
              <InputText name="addressDetail" ref={register} />
            </Row>
            {errors.zipCode && (
              <ErrorMessage>{errors.zipCode.message}</ErrorMessage>
            )}
          </FormGroup>
        )}

        <FormGroup>
          <Button full type="submit">
            다음
          </Button>
        </FormGroup>
      </form>
    );
  },
);

export default DonateStep2;
